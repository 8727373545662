import React, { useState } from "react";
import { Box, Grid, Button, Snackbar, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useRouter } from "./../../util/router";
import { useAuth } from "./../../util/auth";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TabExport = ({ signature }) => {
  const auth = useAuth();
  const router = useRouter();

  const subscribed =
    auth && auth.user && (
      auth.user.stripeSubscriptionStatus === "active" ||
      auth.user.stripeSubscriptionStatus === "trialing"
    );

  // Export Rich Text
  const onExport = async () => {
    if (!auth.user) {
      router.push("/auth/signup?next=/purchase/monthly");
      return;
    }

    if (!subscribed) {
      router.push("/purchase/monthly");
      return;
    }

    var doc = document,
      text = doc.getElementById("renderTable"),
      range,
      selection;

    if (doc.body.createTextRange) {
      range = doc.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = doc.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    setOpen(true);
  };

  // Export HTML
  const onExportHTML = async () => {
    if (!auth.user) {
      router.push("/auth/signup?next=/purchase/monthly");
      return;
    }

    if (!subscribed) {
      router.push("/purchase/monthly");
      return;
    }

    var doc = document,
      text = doc.getElementById("renderTable").outerHTML;

    // copy to clipboard
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
    setOpen(true);
  };

  // Snackbar
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Return Component
  return (
    <>
      <Grid container display="flex" justifyContent="center" spacing={4}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onExport}>
            Copy Signature
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onExportHTML}>
            Copy HTML
          </Button>
        </Grid>
        {/* <Grid item xs={6}>

        </Grid> */}
      </Grid>
      <Box mt={3} display="flex" justifyContent="center">
        <video
          width="100%"
          max-width="640"
          controls
          style={{ maxWidth: '640px' }}
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/gliide-ai.appspot.com/o/assets%2Ffinish_video.mp4?alt=media&token=b9206d73-1638-4139-a067-89a631ade72a"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Box>
      <Box mt={2} display="flex" justifyContent="center">
        <Typography>For Outlook users, we suggestive non-animated signatures with minimal buttons.</Typography>
      </Box>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info">
          Exported Signature to Clipboard
        </Alert>
      </Snackbar>
    </>
  );
};

export default TabExport;
